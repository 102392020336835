<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Domande</h4>
                </div>
                <DataTable :value='augmentedRows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='loading'
                           responsiveLayout='scroll'
                >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>
                    <Column field='question' header='Domanda' :sortable='true' style='min-width:6rem' class='py-0'>
                    </Column>

                    <Column field='lang' header='Lingua' :sortable='true' style='min-width:6rem' class='py-0'>
                        <template #body='slotProps'>
                            <Flag :code="slotProps.data['lang']" :name="languageLabel(slotProps.data['lang'])" :width='24' :class="{'mr-1': true}"/>
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Chatbots/HelpDesk');
const commonStore = createNamespacedHelpers('Common');

import ListHeader from '../../../components/4books/ListHeader';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';

export default {
    data() {
        return {
            filters: null,
            lang_filt: ''
        };
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        ...commonStore.mapGetters(['languages']),
        augmentedRows(){
            if (this.rows && this.rows.length){
                return this.rows.map(row => {
                    //spacchettiamo la domanda
                    row['question'] = "";
                    if (row['content']){
                        const pieces = row['content'].split(/\r?\n|\r|\n/g);
                        if (pieces){
                            if (pieces.length > 1){
                                row['question'] = pieces[1];
                            } else {
                                row['question'] = pieces[0];
                            }
                        }
                    }
                    return row;
                });
            }
            return [];
        }
    },
    methods: {
        ...mapActions(['search']),
        refreshList() {
            this.search({
                lang: this.lang_filt,
            }).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({});
        },
        clearFilter() {
            this.initFilters();
        },
    },
    mixins: [
        Notifications,
        Navigations,
    ],
    components: {
        ListHeader
    }
}
</script>
